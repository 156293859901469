<template>
  <div class="w-100">
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="mb-0">{{ $t('tshirts') }}</h5>
      <div class="text-right">
        <b-button @click="openModalCreate()"><i class="fa fa-plus px-2"></i></b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered hover :items="tshirts" :fields="columns">
      <div slot="name" slot-scope="row" class="text-center">
        <strong v-if="row.item.active">{{ row.value }}</strong>
        <span v-else>{{ row.value }}</span>
      </div>
      <div slot="image" slot-scope="row" class="text-center">
        <img :src="row.value" :alt="row.item.name" class="img-icon">
      </div>
      <template slot="actions" slot-scope="row">
        <div class="w-100 text-center">
          <b-button v-if="!row.item.active" class="mx-2" @click="activate(row.item.id)"><i class="fa fa-check px-2"></i></b-button>
          <b-button class="mx-2" @click="openModalEdit(row.item)"><i class="fa fa-pencil px-2"></i></b-button>
          <b-button class="mx-2" variant="danger" @click="openModalDestroy(row.item)"><i class="fa fa-trash px-2"></i></b-button>
        </div>
      </template>
    </b-table>
    <b-form @submit.prevent="save()">
      <b-modal ref="modalTshirt" :title="modalTitle">
        <div class="form-group">
          <label for="name">{{ $t('name') }}</label>
          <b-form-input name="name" id="name" v-model="tshirt.name" v-validate="'required|max:25'" :class="validateState('name')"/>
        </div>
        <div class="form-group">
          <label for="file">{{ $t('findImage') }}</label>
          <div class="custom-file" :class="validateState('language') ? 'is-valid' : 'is-invalid'">
            <input type="file" ref="inputFile" class="custom-file-input" id="file" :lang="lang" @change="imagePreview($event)" v-validate="'image|ext:png,jpg,jpeg|size:1024'" accept=".png,.jpg,.jpeg" name="findImage" :data-vv-as="$t('image') | toLowerCase">
            <label class="custom-file-label" ref="lblFileName" for="file">{{ $t('findImage') }}</label>
          </div>
        </div>
        <div class="form-group text-center">
          <img v-if="imgPath" :src="imgPath" :alt="team.name" class="img-fluid img-thumbnail">
        </div>
        <template slot="modal-footer">
          <b-button type="button" @click="$refs.modalTshirt.hide()">{{ $t('cancel') }}</b-button>
          <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
        </template>
      </b-modal>
    </b-form>
    <b-modal ref="modalDelete" :title="$t('confirmTitleDelete')" headerBgVariant="danger" headerTextVariant="light">
      <p>{{$t('confirmTextDelete')}}</p>
      <strong>{{ tshirt.name }}</strong>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDelete.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="danger" @click="destroy" class="ml-3">{{$t('delete')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      tshirts: [],
      tshirt: {},
      type: 'S',
      file: null,
      modalTitle: '',
      imgPath: '',
      columns: [
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'image', label: this.$t('image'), sortable: true },
        { key: 'actions', label: '' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'team'
    ])
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    activate (tshirtId) {
      const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/tshirts/${this.tshirt.id}/activate`
      this.$axios.post(path).then(() => {
        this.tshirts = this.tshirts.map(t => {
          if (t.id === tshirtId) {
            t.active = true
          } else {
            t.active = false
          }
          return t
        })
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        this.$refs.modalDelete.hide()
      })
    },
    openModalDestroy (tshirt) {
      this.tshirt = tshirt
      this.$refs.modalDelete.show()
    },
    destroy () {
      const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/tshirts/${this.tshirt.id}/delete`
      this.$axios.delete(path).then(() => {
        this.tshirts = this.tshirts.filter(t => {
          return t.id !== this.tshirt.id
        })
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        this.$refs.modalDelete.hide()
      })
    },
    openModalEdit (tshirt) {
      this.type = 'U'
      this.imgPath = tshirt.image
      this.tshirt = JSON.parse(JSON.stringify(tshirt))
      this.$refs.modalTshirt.show()
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = new FormData()
          payload.append('name', this.tshirt.name)
          if (this.file) {
            payload.append('image', this.file)
          }
          if (this.type ==='S') {
            const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/tshirts/store`
            this.$axios.post(path, payload).then(response => {
              this.tshirts.unshift(response.data.data)
              this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
              this.$refs.modalTshirt.hide()
            })
          } else {
            const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/tshirts/${this.tshirt.id}/update`
            this.$axios.post(path, payload).then(response => {
              const data = response.data.data
              this.tshirts = this.tshirts.map(t => {
                if (t.id === this.tshirt.id) {
                  t.name = data.name,
                  t.image = data.image
                }
                return t
              })
              this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
              this.$refs.modalTshirt.hide()
            })
          }
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    openModalCreate () {
      this.type = 'S'
      this.modalTitle = this.$t('create')
      this.tshirt = {}
      this.imgPath = ''
      this.$refs.modalTshirt.show()
    },
    imagePreview (e) {
      const input = e.target  
      this.file = input.files[0]
      const _self = this
      if (input.files && input.files[0]) {
        _self.$refs.lblFileName.innerHTML = this.file.name
        var reader = new FileReader()
        reader.onload = function (e) {
          _self.imgPath = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        _self.file = []
        _self.$refs.lblFileName.innerHTML =  _self.$t('findImage')
      }
    },
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/tshirts`
      this.$axios.get(path).then(response => {
        this.tshirts = response.data.data
      })
    }
  }
}
</script>
